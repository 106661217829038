import React from "react";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import img3 from "../../images/job-offer.png";
import img4 from "../../images/double-check.png";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function InvestorContribution() {
  const benefits = [
    {
        img: img2,
        title: "Improve Economy",
        bodycopy: " Institutional lenders can contribute to economic improvement by fostering sustainable agricultural practices and promoting inclusive rural development.",
      },
      {
        img: img3,
        title: "Create Jobs",
        bodycopy: "Institutional lenders can create jobs by investing in mechanization, technology, and value-added processing in the agricultural sector.        ",
      },
  ];
  return (
    <div className=" subSectionWrapper">
      <h2 className="text-center container-title">
      Institutional Lender Contribution
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={6} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
