import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import AdvertisementCBN from "../gbrEngage/Advertisement/AdvertisementCBN";
import { Row, Col } from "react-bootstrap";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
 
export default function AdvertisementService() {
 
 
  return (
    <>
<HeaderComponent/>
<UpdatesBanner
        className="Advertisement-bg"
        bannerText={[
          "Advertisement"
        ]}
      />
    <div className="subSectionWrapper  pb-0">
             <h3 class="container-title text-center">Advertisements</h3>
 
 
<HomeSubSection
  className1="aboutBgGrey"
  className="rowReverseAbout--mod borderedCards borderedCards--mod"
  className3="image19"
  descriptionTitle="
  Machinery Providers"
  description="
  The Agro Dealer Network (ADN) offers comprehensive advertisement services to agri-input manufacturers, enabling them to effectively promote their products to a targeted audience. By leveraging ADN's extensive network and expertise, manufacturers can enhance their visibility, reach more potential customers, and drive sales. ADN's advertising solutions are designed to cater specifically to the agricultural sector, ensuring that the promotional efforts resonate with farmers and other key stakeholders within the industry.
 " 


/>
<AdvertisementCBN />
    </div>
    <FooterComponent/>
    </>
 
  );
}
 
