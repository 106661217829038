import React, { useState } from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CTA from "../CTA/CTA";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForManufacturers.css";
import ManufacturersBenefits from "./ManufacturersBenefits";
import ManufacturesForm from "./ManufacturesForm";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import ManufacturesRoadMap from "./ManufacturesRoadMap";
import ManufacturerServices from "./ManufacturerServices";
import BrandCTA from "../BrandCTA/BrandCTA";
import MSMECoustomerBenefit from "../WebSite/MSMECoustomerBenefit/MSMECoustomerBenefit";
import BrandChallenges from "./BrandChallenges";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
export default function ForManufactures() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
        bannerText={[
          "We Build Brands and help the existing one to Package",
          <br className="d-lg-block d-none"></br>,
          "Organic and Traceable Products",
        ]}
        buttonText2="Sign in"
        buttonText3="Sign up"
        href2="https://abn.gbrapp.com/"

        href3="https://abn.gbrapp.com/signup"      />
      <div className="main-wrapper2">
        <BrandChallenges />
      </div>
      <Container>
        <SolutionEcosystem
          type="Brands"
          bodycopy="ADN Network provides an ecosystem for brands to ovecome the challenges.
          "
        />
        {/* <TitleDescription
    classname="mb-0"
    classname2="mb-0"
          title="About Brands"
          description="GBR Farming specializes in building and enhancing brands, offering expertise in packaging organic and traceable products. Whether creating new brands or improving existing ones, we are dedicated to crafting unique and trustworthy identities for agricultural products that meet the highest standards of quality and sustainability."/>   */}
      </Container>
      <div className="main-wrapper2">
        <Container>
          <ManufacturerServices />
        </Container>
      </div>
      {/* <div className="for-manufacturers-wrapper"> */}

      {/* <ManufacturesRoadMap /> */}
      {/* </div> */}

      <Container className="mt-4">
        <MSMECoustomerBenefit />
      </Container>
      <BrandCTA />
      <FooterComponent />
    </>
  );
}
