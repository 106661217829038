import React from 'react';
import Icon1 from "../../images/ServiceIcons/sustainable.svg";
import Icon2 from "../../images/ServiceIcons/profits.svg";
import Icon3 from "../../images/ServiceIcons/cash-flow.svg";
import Icon4 from "../../images/ServiceIcons/bar-chart (1).svg";
import OurServiceCardContainer from './OurServiceCardContainer';




export default function PNServices({title, services}) {
    const ABNServices = [


      {
        image: Icon1,
        title: "Field Support Service",
        bodycopy:
          "Facilitating seamless asset relocation, buyback assistance, and providing expert support for nutrients, supplements, and care services to optimize operational efficiency.",
      },
     


      {
        image: Icon2,
        title: " Profit-generating Service ",
        bodycopy:
          "Monetize unutilized capacity with a profit-generating service, maximizing business revenue through strategic resource optimization.          ",
      },


      {
        image: Icon3,
        title: "Financial Service ",
        bodycopy:
          "ADN offers a comprehensive financial service to businesses, specializing in facilitating loans to support their growth and financial needs.          "
      },
   


      {
          image: Icon4,
          title: " Business Growth Service",
          bodycopy:
            " Empowering businesses to upgrade and thrive through tailored strategies, innovative solutions, and expert support for sustainable expansion and development."
        },


        {
          image: Icon4,
          title: " Traceability & Provenance By Operator",
          bodycopy:
            " Traceability and provenance by operator as a service ensures transparent asset tracking, leveraging advanced tech for reliability and compliance, fostering trust, and optimizing operations.            "
        },
        {
          image: Icon4,
          title: " Farm /Plant  Production  KPI visualization (P.O Live Status Monitoring)",
          bodycopy:
            " Farm/plant production KPI visualization for live status monitoring is a service provided by the Digital Transformation Network, enabling real-time tracking and informed decision-making to enhance operational efficiency and productivity."
        },
        {
          image: Icon4,
          title: " Developer Quality Assistant ( Developer QA) for ensured quality ",
          bodycopy:
            "Developer Quality Assistant (Developer QA) is a service provided by the Digital Transformation Network, optimizing software development processes and ensuring high-quality outcomes through advanced tools and methodologies."
        },
        {
          image: Icon4,
          title: " Farm to Factory Traceability to create trust",
          bodycopy:
            " Farm-to-factory traceability offers a significant benefit for food processing enterprise customers by ensuring transparency in the supply chain, enhancing product quality, safety, and compliance with regulations, while also building consumer trust and loyalty. "
        },
        {
          image: Icon4,
          title: " Real Time Visibility",
          bodycopy:
            " Real-time visibility in manufacturing offers a crucial benefit for food processing enterprise customers, enabling them to optimize operations, track production progress, and respond promptly to any issues, thus enhancing efficiency and productivity."
        },


























        // {
        //   image: Icon1,
        //   title: " PN-PPN ",
        //   bodycopy:
        //     "Manage factory & distributed plant production through farmer franchise network & Own QA .",
        // },
       


        // {
        //   image: Icon2,
        //   title: " PN-FPN ",
        //   bodycopy:
        //     "Manage Organic and Normal field production in own farms through own operators and own QA.",
        // },


        // {
        //   image: Icon3,
        //   title: "PN-CF ",
        //   bodycopy:
        //     " Manage Organic and Normal Agri-Assets production through contract farming. "
        // },
     


        // {
        //     image: Icon4,
        //     title: "  PN-AAMC",
        //     bodycopy:
        //       "Manage Agri-Assets through Agri-entrepreneurs and their operators to manage the approved farms."
        //   },
          
      ];
  return (
    <OurServiceCardContainer services={ABNServices} title={title}/>
  )
}
