import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import "./HeaderComponent.css";
import React, { useEffect, useState } from "react";
import { List, ListItem, Drawer, IconButton, Link } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../images/Asset 2@4x.png";
import Logo1 from "../../../images/Asset 1@4x.png";
import { NavLink } from "react-router-dom";
const useStyles = makeStyles({
  drawer: {
    width: 250,
  },
});

export default function HeaderComponent({ className }) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl2(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [trigger, setTrigger] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 200) {
      setTrigger(true);
    } else {
      setTrigger(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const classes = useStyles();

  const loginHandler = (username) => {
    localStorage.setItem("userType", username);
  };
  return (
    <AppBar
      className={`${
        trigger ? "gbr_header boxShadow" : "gbr_header"
      } ${className}`}
    >
      <Toolbar>
        {/* <Typography className="logo navTitle"> */}
        <div className="logo navTitle">
        <NavLink to="/">
            {/* <img className="logo1" src={Logo} alt="Logo" /> */}
           <h2 className="mb-0">ADN Network</h2> 
          </NavLink>

        </div>
        {/* <div className="logo navTitle">
          <NavLink className="" to="/">
            <img className="logo1" src={Logo} alt="Logo" />
          </NavLink>

     
        </div> */}
        {/* </Typography> */}
        <div className="desktopNavbar">
          {/* <a href="https://gbrapp.com/"  target="_blank" rel="noreferrer noopener" >
            Main Webstie
          </a> */}
          <NavLink to="/" exact={true}>
            Home
          </NavLink>
          <NavLink to="/About">About Us</NavLink>
          <NavLink to="/customized-abn-network">Our Network</NavLink>
          {/* <NavLink to="/agriminer">For AgriMiner</NavLink> */}

          {/* <Button className="whiteTextColor">
            <a href="/agribusiness">Agribusiness</a>
          </Button> */}

          {/* <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            Agribusiness
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock={true}
            className="mt-4"
          >
            <MenuItem
              onClick={handleClose}
              component={NavLink}
              to="/farm-enterprise"
            >
              Farm Enterprise
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={NavLink}
              to="/agro-dealers"
            >
              Agro-Dealers
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={NavLink}
              to="/agri-food-manufacturers"
            >
              Agrifood Manufacturers{" "}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a
                className="text-decoration-none text-black"
                href="http://gbrfarming.com/"
              >
                Micro-Entrepreneurs
              </a>
            </MenuItem>
          </Menu> */}

          {/* <Button
            aria-controls="simple-menu1"
            aria-haspopup="true"
            onClick={handleClick1}
            color="inherit"
          >
            Technology
          </Button>
          <Menu
            id="simple-menu1"
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose1}
            disableScrollLock={true}
            className="mt-4"
          >
            <MenuItem
              onClick={handleClose1}
              component={NavLink}
              to="/ExploreNetwork"
            >
              Explore Network
            </MenuItem>
            <MenuItem
              onClick={handleClose1}
              component={NavLink}
              to="/ExploreTechnology"
            >
              Explore Apps
            </MenuItem>
            <MenuItem
              onClick={handleClose1}
              component={NavLink}
              to="ExploreAPIs"
            >
              Explore APIs{" "}
            </MenuItem>
          </Menu> */}
          {/* <NavLink to="/agribusiness">Agribusiness</NavLink> */}

          {/* <a target="_blank" href="http://abn.gbrfarming.com/">
            Sign In
          </a>
          <a target="_blank" href="http://abn.gbrfarming.com/auth/3/signup">
            Sign Up
          </a> */}

          {/* <NavLink to="/auth/3/signup">Sign Up</NavLink>  */}
          <NavLink to="/auth/3/signin">Sign In</NavLink>

          {/* <NavLink to="/Agent">Agent</NavLink>

          {/* <NavLink to="/Pricing">Pricing</NavLink> */}
          {/* <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div> */}
          {/* <Button className="whiteTextColor">
            <a href="/businesspartner">Partner</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/AgriMiner">AgriMiner</a>
          </Button> */}
          {/* <Button className="whiteTextColor">
            <a href="/Investments">Investments</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/Investor">Investor</a>
          </Button> */}
          {/* <Button className="whiteTextColor"> */}
          {/* <NavLink onClick={loginHandler("Farmer")} to="/auth/3/signin">
            Login
          </NavLink> */}
          {/* </Button> */}
        </div>
        <IconButton
          className="menuIcon"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setIsDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          className="drawer"
          anchor={"right"}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <span
            className="iconCnt header-cross-icon closeCnt"
            onClick={() => setIsDrawerOpen(false)}
          >
            <i className="material-icons">close</i>
          </span>
          <br></br>
          <List className={classes.drawer}>
            <Button className="whiteTextColor d-block ps-4">
              <NavLink exact={true} to="/">
                Home
              </NavLink>
            </Button>
            <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/About">About Us</NavLink>
            </Button>
            <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/customized-abn-network">Our Network</NavLink>
            </Button>
            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/agriminer">For AgriMiner</NavLink>
            </Button> */}

            {/* <Button
              className="navDrop d-block ps-4 "
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              Agribusiness
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disableScrollLock={true}
              className="mt-4"
            >
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/farm-enterprise"
              >
                Farm Enterprise
              </MenuItem>



              
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/agro-dealers"
              >
                Agro-Dealers
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/agri-food-manufacturers"
              >
                Agrifood Manufacturers{" "}
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a
                  className="text-decoration-none text-black"
                  href="http://gbrfarming.com/"
                >
                  Micro-Entrepreneurs
                </a>
              </MenuItem>
            </Menu> */}

            {/* <Button
              className="navDrop d-block ps-4 "
              aria-controls="simple-menu1"
              // aria-haspopup="true"
              onClick={handleClick1}
              color="inherit"
              // aria-controls="simple-menu1"
              // aria-haspopup="true"
              // onClick={handleClick1}
              // color="inherit"
            >
              Technology
            </Button>
            <Menu
              id="simple-menu1"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose1}
              disableScrollLock={true}
              className="mt-4"
            >
              <MenuItem
                onClick={handleClose1}
                component={NavLink}
                to="/ExploreNetwork"
              >
                Explore Network
              </MenuItem>
              <MenuItem
                onClick={handleClose1}
                component={NavLink}
                to="/ExploreTechnology"
              >
                Explore Apps
              </MenuItem>
              <MenuItem
                onClick={handleClose1}
                component={NavLink}
                to="ExploreAPIs"
              >
                Explore APIs{" "}
              </MenuItem>
            </Menu> */}

            {/* <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/auth/3/signup"
              >
                Sign Up
              </MenuItem> */}

            {/* <Button className="whiteTextColor d-block ps-4">
              <a target="_blank" href="http://abn.gbrfarming.com/">
                Sign In
              </a>
            </Button>

            <Button className="whiteTextColor d-block ps-4">
              <a target="_blank" href="http://abn.gbrfarming.com/auth/3/signup">
                Sign Up
              </a>
            </Button> */}

            {/* <Button
className="navDrop d-block ps-4 "
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            Agri-Entrepreneur
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock={true}
            className="mt-5 mx-3"
          >
         <MenuItem onClick={handleClose} component={NavLink} to="/ForGrowers">
              Grower
            </MenuItem>
            <MenuItem onClick={handleClose} component={NavLink} to="/FarmerFranchise">
              Farmer Franchise
            </MenuItem>
       
          </Menu> */}
            {/* </ListItem> */}
            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/ForBrands">Brands</NavLink>{" "}
            </Button> */}

            {/* <Button className="whiteTextColor d-block ps-4">
              <a href="/Agent">Agent</a>
            </Button> */}

            {/* <ListItem button> */}

            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/Pricing">Pricing</NavLink>
            </Button> */}

            {/* <ListItem button>
              <Button className="whiteTextColor">
                <a href="/businesspartner">Partner</a>
              </Button>
            </ListItem>

            <ListItem button>
              <Button className="whiteTextColor">
                <a href="/AgriMiner">AgriMiner</a>
              </Button>
            </ListItem> */}
            {/* <ListItem button>
              <Button className="whiteTextColor">
                <a href="/Investments">Investments</a>
              </Button>
            </ListItem> */}
            {/* <Button className="whiteTextColor d-block ps-4">
                <NavLink to="/auth/3/signin">Login</NavLink>
              </Button> */}
            {/* <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/agribusiness">Agribusiness</NavLink>
            </Button> */}
            <Button className="whiteTextColor d-block ps-4">
              <NavLink to="/auth/3/signin">Sign In</NavLink>
            </Button>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>

    /* <AppBar className="gbr_header" variant="outlined" position="static">
        <Toolbar>
          <Typography variant="h6" className="logo navTitle">
            <Hidden only={["xs"]}>
              <a href="/">GBR</a>
            </Hidden>

            <Hidden smUp>
              <a href="/">GBR</a>
            </Hidden>
          </Typography>
          

          <Button className="whiteTextColor">
            <a href="/agristack">Technology</a>
          </Button>
          
          <Button className="whiteTextColor">
            <a href="/businesspartner">Partner</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/Producer">Become a Producer</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/becomeBuyer">Become a Buyer</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/Investments">Investments</a>
          </Button>
          <Button className="whiteTextColor">
            <a href="/auth/3/signin">Invest Now</a>
          </Button>
          
        </Toolbar>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose.bind(this, -1)}
          PaperProps={{
            style: {
              
              width: 200,
            },
          }}
        >
          <MenuItem onClick={this.handleClose.bind(this, 1)}>
            Investor App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 14)}>
            Grower App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 3)}>
            Business App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 5)}>
            Agent Miner App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 4)}>
            Buyer App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 7)}>
            Off-Taker
          </MenuItem>
          
          <MenuItem onClick={this.handleClose.bind(this, 10)}>
            Transport App
          </MenuItem>
          <MenuItem onClick={this.handleClose.bind(this, 2)}>
            Veterinary App
          </MenuItem>
        </Menu>
      </AppBar> */
  );
}
