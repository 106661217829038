import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function IaaMarketNavbar() {
    const navData = [
        { to: "/iaaMarketplace", link: " For Ago Dealers" },
        { to: "/social-iaaMarketplace-for-institutional-lenders", link: " For Institutional Lenders " },
      ];
  return (
    <>      <SecondaryNavbar props={navData} />
    </>
  )
}
