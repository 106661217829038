import React from "react";
import "./POServices.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import PrivateLabelling from "../../images/Po/status.jpg";
import NewDevelopment from "../../images/Po/paperless.jpg";
import BrandCTA from "../BrandCTA/BrandCTA";
export default function POServices(){
    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="POBanner text-white"
         bannerText="CMN Service"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="ADN Network extends its service of Work Order Status to brands, offering real-time monitoring and updates on the progress of their orders throughout the production cycle. By leveraging advanced tracking systems and communication channels, ADN Network enables brands to stay informed about the status of their work orders, ensuring timely delivery, mitigating potential delays, and fostering efficient collaboration between all parties involved in the manufacturing process.
          "
          title="PO Monitoring"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="ADN Network serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. brands benefit from ADN Network's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description=" ADN Network delivers a Paperless Process service to brands, streamlining operations by digitizing workflows and eliminating the reliance on traditional paper-based documentation. Through the integration of digital tools and platforms, ADN Network facilitates seamless communication, data management, and task execution, enabling brands to enhance efficiency, reduce environmental impact, and optimize resource utilization while ensuring compliance with industry regulations and standards."
          title="Paperless Process"
          src={NewDevelopment}
        />
        <BrandCTA/>
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="ADN Network provides brands with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}