import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function AgroDealerPricing() {
  // const freeFeature = [ "Manage upto 10 growers", "Monetize Storage Capacity", "Monetize farming capacity(Manage up to 100 assets) ", "Feed management", "Asset Relocation Support", "Can raise the loan request", "Buy Back Support", "Request for labour card", "Care service for distibuted assets",  "online Report generation"];
  const BasicFeature = [  "AgroCare Marketplace", "Billing Service", "GBR Trace"];
  const StandardFeature = [ "AgroCare Marketplace", "Billing Service", "GBR Trace", "Own, operate & manage ARN Network", "AR onboarding service", "Report"];
  const PreferredFeature = [ "AgroCare Marketplace", "Billing Service", "GBR Trace", "Own, operate & manage ARN Network", "AR onboarding service", "Report", "Social iaa Marketplace"];
  const PremiumFeature = [ "AgroCare Marketplace", "Billing Service", "GBR Trace", "Own, operate & manage ARN Network", "AR onboarding service", "Report", "Social iaa Marketplace", "Business Diversification Service"];



  const data = [

    {
      title: "Free",
      annually: "40000",
      saving:"7988",
      btnText: "Sign up ",
      price: "3999",
      features: BasicFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "AgroDealer");
      }
    },
    {
      title: "Standard",
    
      annually: "97988",
      saving:"10000",
      btnText: "Sign up ",
      price: "8999",
      features: StandardFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "AgroDealer");
      }
    },

    {
        title: "Preferred",
        annually: "100000",
        saving:"31988",
        btnText: "Sign up ",
        price: "10999",
        features: PreferredFeature,
        href:"/auth/3/signup",
        onClick: function() {
          localStorage.setItem("userType", "AgroDealer");
        }
      },

      {
        title: "Premium",
        annually: "110000",
        saving:"57988 ",
        btnText: "Sign up ",
        price: "13999",
        features: PremiumFeature,
        href:"/auth/3/signup",
        onClick: function() {
          localStorage.setItem("userType", "AgroDealer");
        }
      },
  ];

  return (
    <>
      <Row className="justify-content-center">
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex  mb-4">
                <PricingCards onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText} saving={e.saving}/>
            </Col>
        })}
      </Row>
    </>
  );
}
