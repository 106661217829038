import React, { Component } from "react";
import "./GbrFormsComponent.css";
import ReactPaginate from "react-paginate";
import $, { event } from "jquery";
import OutsideClickHandler from "react-outside-click-handler";
import { Col, Row } from "react-bootstrap";
import { Container } from "@material-ui/core";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import Advertisement from "../gbrEngage/Advertisement/Advertisement";
import AdvisaryComponent from "../gbrEngage/Advisary/Advisary";
import BuyInput from "../gbrEngage/BuyInput/BuyInput";
import GovtSchemesComponent from "../gbrEngage/GovtSchemes/GovtSchemes";
import PriceDiscovery from "../gbrEngage/PriceDiscovery/PriceDiscovery";
import SiteUpdates from "../gbrEngage/SiteUpdates/SiteUpdates";
import InputSupplies from "../gbrEngage/InputSupplies/InputSupplies";
import Club from "../Club/Club";
import ClubUpdates from "../gbrEngage/ClubUpdates/ClubUpdates";
import ClubMainPage from "../Club/ClubMainPage";
import AdvisaryCBNComponent from "../gbrEngage/Advisary/AdvisaryCBN";
import AdvertisementCBN from "../gbrEngage/Advertisement/AdvertisementCBN";
import JobsComponent from "../gbrEngage/Jobs/Jobs";
import AgriAssets from "../ExploreProducts/AgriAssets";
import ProducersBenefits from "../WebSite/ProducersBenefits/ProducersBenefits";
import ProducersJourney from "../WebSite/ProducersJourney/ProducersJourney";
import SiteWeatherManagement from "../SiteWeatherManagement/SiteWeatherManagement";
import IAAProducers from "../WebSite/IAATab/IAAProducers";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import TransporterImg from "../../images/gbrCBN/CBNImg7.webp";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import StorageProvider from "../gbrEngage/StorageProvider/StorageProvider"
class GbrFormsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumeruserparent: "consumer2",
      tenders: [],
      tendersFilter: [],
      offset: 0,
      tensdersPageCount: 1,
      perPage: 6,
      currentPage: 0,
      checked: false,
      postCategoryIdVlaue: "",
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      foodProcessor: ["FOOD-PROCESSOR"],
      Machinery: ["PMC", "FMC"],
      ahp:["AHSP"]
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.postCategoryIdValue();
    this.adminsDropdownClick();
  }

  postCategoryIdValue() {
    fetch(
      `${window.appConfig.getContentCategories}${window.appConfig.Relam}${window.appConfig.Relam}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "firstttttttttt");
        const postid = result.categoryList.filter((data) => {
          return data.categoryname === "SITE-UPDATES";
        });
        this.viewAllSiteUpdates(postid[0].categoryid);
        this.setState({
          postCategoryIdVlaue: postid[0].categoryid,
        });
      })
      .catch((err) => console.log("err", err));
  }

  viewAllSiteUpdates(postid) {
    fetch(
      `${window.appConfig.getContentList}${window.appConfig.Relam}category/${postid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tenders: result.contentList,
          tensdersPageCount: Math.ceil(
            result.contentList.length / this.state.perPage
          ),
        });
      });
  }

  adminsDropdownClick() {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}FOOD-PROCESSOR`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tendersFilter: result.users,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  organizationsDropdownClick() {
    $("#dropdownOrganizations").toggle();
  }

  organizationsOnClick(org) {
    fetch(
      `${window.appConfig.getContentList}${window.appConfig.Relam}contentowner/${org}/${this.state.postCategoryIdVlaue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        this.setState({
          tenders: result.contentList,
        });
      })
      .catch((err) => console.log("err", err));
    $("#dropdownOrganizations").hide();
  }

  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => { }
    );
  };
  /*End of pagination */

  render() {
    const { tenders } = this.state;
    const siteUpdatesList = tenders.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <>

        <div className="mt-4 update-container-wrapper">
          {/* <h1 className="text-center container-title">
              Agri-producers Engagement Services
            </h1> */}
          <h3 class="container-title text-center">Advertisements</h3>


          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image19"
            descriptionTitle="
            Machinery Providers"
            description="
            ADN is your trusted source for a diverse range of agricultural machinery. Whether you're a farmer or an farmer franchise, we supply the equipment you need to optimize your operations. Count on us to provide reliable machinery that supports your farming goals and drives efficiency.
            "
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Agri-producers-Machinery-Provider"
          />
          <AdvertisementCBN />

          <h3 class="container-title text-center">Advisory</h3>

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image16"
            description="Our dedicated Agri-producers' Advisors are here to provide valuable insights on animal health and field reports. We understand that successful farming requires comprehensive support, which is why our advisors are equipped to assist you in making informed decisions about your animals' well-being and optimizing your field operations.
            "
            descriptionTitle="
            Agri-producers' Advisors"
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Trainer"
          />
          <AdvisaryCBNComponent />

          <h3 class="container-title text-center">Buy Inputs</h3>

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image20"
            description="we collaborate with leading nutrition companies that specialize in providing essential dietary solutions for animals and farms. This partnership ensures that agri-producers have access to expert guidance and high-quality nutrition options to enhance the health and productivity of their animals and overall agricultural operations."
            descriptionTitle="
            Nutrition Companies"
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
          />

          <HomeSubSection
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image24"
            description="At ADN, we've built a supportive network where renowned agri-input companies come together to provide essential solutions for farming animals and fields. This collaborative effort ensures agri-producers receive expert guidance and high-quality agri-input options, boosting the health, productivity, and sustainability of their farms.
            "
            descriptionTitle="
            Agri Input Companies"
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Agri Input Companies"
          />

          <BuyInput />

          <h3 class="container-title text-center">Machinery</h3>

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards--mod"
            className3="image28"
            description="Within the vibrant ADN community, we join forces with renowned agri machinery providers to deliver essential solutions tailored for farms. This cooperative effort ensures agri-producers benefit from expert guidance and superior agri machinery options, ultimately enhancing the efficiency, productivity, and sustainability of their farming operations."
            descriptionTitle="
  Agri Machinery"
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
          />

          <HomeSubSection
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image29"
            description="Within the dynamic ADN community, we partner with leading processing machinery providers to offer essential solutions customized for farms. This collaborative effort ensures agri-producers gain access to expert guidance and high-quality processing machinery options, resulting in improved efficiency, productivity, and sustainability for their farming operations."
            descriptionTitle="
  Processing Machinery"
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Agri Input Companies"
          />

          <Container className="mt-4">
            <ProcessedProducts
              type="MACHINERY"
              userType={this.state.Machinery}
              cbn="true"

            />
          </Container>
          <h3 class="container-title text-center">Govt Schemes</h3>

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image6"
            descriptionTitle="
            Government Organizations"
            description="In our community, both government organizations and non government organizations play an active role in sharing information about government schemes. This collaborative effort ensures that agri-producers have access to a wealth of important programs and initiatives designed to bolster the agricultural sector."
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Agri-producers-Machinery-Provider"
          />
          <GovtSchemesComponent />
          <div className="mb-lg-5 mb-4">
            <JobsComponent />
          </div>
          <div className="mb-lg-5 mb-4">
            <PriceDiscovery />
          </div>
          <div className="mb-lg-5 mb-4">
            <ClubUpdates />
          </div>

          <div className="mb-lg-5 mb-4">
            {/* <SiteWeatherManagement /> */}
          </div>

          <div id="tendersContainer" className="container-fluid">
            <h3 id="AgriAssets" className="container-title">
              {" "}
              Agri Assets
            </h3>
            <Container>
              <ProcessedProducts
                type="AGRI-ASSETS"
                userType={["FOOD-PROCESSOR"]}
                cbn="true"
              />



            </Container>
          </div>


          <h3 class="container-title text-center">Storage</h3>

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image23"
            descriptionTitle="
            Storage Providers"
            description="Partnering with established storage providers, we offer essential storage solutions for agricultural produce and products. This collaboration ensures agri-producers have reliable access to storage facilities, facilitating the secure preservation and effective management of their goods."
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Storage Providers"
          />
          {/* <StorageProvider /> */}

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            description="Teaming up with proficient transporters, we facilitate the seamless movement of agricultural produce. This partnership ensures agri-producers have access to efficient transportation solutions, enabling the smooth and timely delivery of their goods to the intended destinations."
            descriptionTitle="
            Transporters"
            src={TransporterImg}
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Transporters"
          />

          <h3 class="container-title text-center">Contaract Maufacturers</h3>

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards--mod"
            className3="image33"
            descriptionTitle="Contract Manufacturers"
            description="Partnering with established storage providers, we offer essential storage solutions for agricultural produce and products. This collaboration ensures agri-producers have reliable access to storage facilities, facilitating the secure preservation and effective management of their goods."
            href5="http://abn.gbrapp.com/auth/undefined/signup"
            buttoncont2="Register"
            href4="http://abn.gbrapp.com/"
            buttoncont1="Sign In"
            userType="Storage Providers"
          />
        </div>


        {/* 
        <div className="MSMEBenfitJourney">
          <ProducersBenefits />
          <ProducersJourney />
        </div> */}
        {/* <FooterComponent /> */}
      </>
    );
  }
}

export default GbrFormsComponent;
