import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import GBRNetworkBusinessCards from "./GBRNetworkBusinessCards";

export default function GBRNetworkBusiness() {
  const businessdata = [
    {
        redirect : "web",
        href:"/",
        classname : "gbr-network-bg",
      purpose: " TRANSACT SECURELY",
      title: "ADN Network",
      subtitle: "Empowering Next Generation Agro-Dealers         ",
      bodycopy:
        "Introducing Agro-Dealer Network (ADN) as a Trusted & Traceable Supply Chain to transform agro-dealers to Digital Enterprises         ",
    },
    // {
    //     href:"https://www.gbrfarming.com/",
    //     classname:"gbr-farming-bg",
    //     purpose: " MONETIZE UNUTILIZED CAPACITIES        ",
    //     title: "GBR Farming",
    //     subtitle: "Digitize Agri-MSMEs, Maximize Profit!        ",
    //     bodycopy:
    //       "Transforming Agri-MSMEs founders to digital entrepreneurs so that they can monetize unutilized capacities to maximize the profit          ",
    //   },
      {
        href:"https://www.dtnapp.com/",
        classname:"farmUpdateBanner",
        purpose: " ACCESS TO CARE        ",
        title: "GBR Care",
        subtitle: "Nurturing Nature, Caring for All",
        bodycopy:
          "Enhancing Farms, Naturally! Top-Quality Animal & Plant Care Products for Farmers.          ",
      },
  ];

  return (
    <div>
      <Container>
      <h2 className='text-center container-title'>Our Businessess</h2>

        <Row className="subSectionWrapper justify-content-center ">
          {businessdata.map((e)=>{
             return (<>
              <Col md={4} classname = "d-flex flex-column">
                <GBRNetworkBusinessCards classname={e.classname} title={e.title} purpose={e.purpose} bodycopy={e.bodycopy} subtitle={e.subtitle} redirect={e.redirect} href={e.href}/>
              </Col>
            </>);
          })}
        </Row>
      </Container>
    </div>
  );
}
