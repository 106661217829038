import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

export default function FieldSupportServiceSection1() {
  return (
    <div className="subSectionWrapper" id="FieldSupport">
      <h2 className="container-title text-center">Field Support</h2>
      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService1"
        description="
        ADN excels in agricultural solutions, offering specialized Asset Relocation Support that encompasses the smooth transfer of colonies and livestock. Our experienced team ensures a seamless process, prioritizing the well-being of assets during relocation for the success of agricultural ventures."
        descriptionTitle="
      Asset Relocation Support"
      />
      <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService2"
        descriptionTitle="
        Nutrients & supplements support"
        description="ADN is dedicated to supporting Agri-producers by providing essential supplements and nutrients. Our comprehensive range ensures optimal health for both livestock and plants, empowering Agri-producers to enhance productivity and cultivate thriving agricultural ecosystems. With ADN, Agri-producers have a reliable partner committed to the well-being and success of their agricultural endeavors.
    "
      />
      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService3"
        descriptionTitle="
      Buy Back Support"
      description="ADN offers a buy-back service where they provide Agri-producers with the necessary resources, such as seeds or materials, to cultivate specific crops. Once the crops are ready, ADN purchases the yield from the Agri-producers, ensuring a reliable market and income source for them. This mutually beneficial arrangement supports Agri-producers and promotes sustainable agricultural practices."

      />


{/* <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService4"
        descriptionTitle="
            Labour Cards"
        description="Health Insurance benefits through Labour card (Social card).
        ADN is committed to empowering Agri-producers by providing information about labor cards offered by the government. Our aim is to ensure that Agri-producers have access to essential details about these cards, helping them make informed decisions and utilize available resources effectively.
    "
      /> */}


<HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService5"
        descriptionTitle="
      Loan"
      description="ADN facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, ADN is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."

      />


<HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService6"
        descriptionTitle="
            Grow"
        description="ADN operates by directly supplying requested assets to Agri-producers. This approach enables Agri-producers to access essential equipment and resources promptly, aligning with their specific needs and ensuring the seamless progression of their agricultural operations. ADN's commitment to serving Agri-producers is rooted in a resource-oriented model, facilitating sustainable growth within the farming community"
      />
<HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService7"
        descriptionTitle="
      Care service for distibuted assets "
      description="ADN specializes in offering care services for distributed assets, including advisory support for asset management, on-demand vet visits for animals, and agronomy services to optimize field productivity. Our comprehensive solutions ensure the well-being and efficiency of your agricultural operations."

      />
      
    </div>
  );
}
