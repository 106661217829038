import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AgriRetailerBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Connect to expert/advisory",
      bodycopy:
        "Connect to expert/advisory services provides farmers with timely guidance and insights, enhancing decision-making and crop management efficiency.",
    },
    {
      image: Icon10,
      title: "Training  ",
      bodycopy:
      "Training equips farmers with essential skills and knowledge, improving agricultural practices and increasing yields effectively."
    },
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
    {
      image: Icon7,
      title: "Access to insurance ",
      bodycopy:
        "Access to insurance safeguards farmers' investments, providing financial security against crop losses and unforeseen events."
    },
    {
      image: Icon7,
      title: "Access to credits ",
      bodycopy:
      "Access to credits empowers farmers with financial resources to invest in equipment, seeds, and technology, fostering sustainable growth and productivity."
    },
    {
      image: Icon8,
      title: "Access to quality agri-inputs ",
      bodycopy:
      "Access to quality agri-inputs ensures farmers can maximize yield potential and produce high-quality crops sustainably."    },

     {
      image: Icon8,
      title: "Access to farmers facilitation services ",
      bodycopy:
      "Access to farmers facilitation services streamlines processes, enabling farmers to efficiently navigate regulations and access resources for enhanced productivity and profitability." } ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Agro-Dealer Customers Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
