import React from 'react';

import Icon3 from "../../../images/ServiceIcons/veterinarian.svg";
import Icon4 from "../../../images/ServiceIcons/service.svg";
import Icon5 from "../../../images/ServiceIcons/ecology.svg";
import Icon6 from "../../../images/ServiceIcons/supply-chain-management.svg";

import OurServiceCardContainer from '../../OurServices/OurServiceCardContainer';



export default function ARNServices() {
    const ARNServices = [
   
        // {
        //   image: Icon1,
        //   title: " CBN-Agri Asset ",
        //   bodycopy:
        //     "Facilitate Agri asset distribution service for producers .",
        // },
       


        // {
        //   image: Icon2,
        //   title: " CBN-AgriMachinery ",
        //   bodycopy:
        //     "Facilitate machinery asset distribution service for producers.",
        // },


        {
          image: Icon3,
          title: "Agri-Clinic ",
          bodycopy:
            " Facilitate animal and plant care through farmer advisors and nutrition distributors. "
        },
     


    
          {
            image: Icon5,
            title: "Organic input",
            bodycopy:
              "Easy access for agri-producers to buy organic feed from the approved organic feed providers."
          },
        

          {
            image: Icon6,
            title: "FFS Service",
            bodycopy:
              "ARN offers Farmer Facilitation Service (FFS) to streamline agricultural processes and support farmers, enhancing efficiency and productivity in farming practices."
          }, {
            image: Icon6,
            title: "Care Services (animal and plant care )",
            bodycopy:
              "ARN offers comprehensive Care Services for animals and plants, ensuring their well-being and optimal growth through specialized care and attention."
          },

      ];
  return (
    <div className='main-wrapper2'>
    <OurServiceCardContainer services={ARNServices}  title="ARN Services"/>
    </div>
  )
}
