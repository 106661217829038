// import React from "react";
// import FooterComponent from "../WebSite/Footer/FooterComponent";
// import HeaderComponent from "../WebSite/Header/HeaderComponent";
// import DigitalTrust from "../../../src/images/aboutImg/closeup-shot-beekeeper-holding-honeycombs-frame-with-many-bees-making-honey.jpg";
// import Trading from "../../../src/images/gbrAbout/aboutimg4.webp";
// import Blockchain from "../../../src/images/aboutImg/business-success-concept-wooden-table-top-view-hands-protecting-wooden-figures-people (1).jpg";
// import Farming from "../../../src/images/aboutImg/shot-beekeeper-beekeeping-suit-standing-near-row-beehives-his-apiary-copyspace.jpg";
// import Values from "../../../src/images/gbrAbout/aboutimg6.webp";
// import CareImg from "../../../src/images/aboutImg/full-length-view-beekeeper-working-landscapes-with-confident-expression-agrarian-natural-summer-honeycombs-concept.jpg";
// import TeamImg from "../../../src/images/gbrAbout/aboutimg7.webp";
// import "react-awesome-slider/dist/styles.css";
// import AboutBanner from "./AboutBanner";
// import AboutSubSection from "../aboutSubSection/AboutSubSection";

// export default function AboutusComponent() {
//   return (
//     <>
//       <HeaderComponent />
//       <AboutBanner />
//       <div className="about-main-wrapper">
//         <AboutSubSection
//           className="rowReverseAbout"
//           // description="GBR create Social & Business Impact by transforming the Agri-producers into Agri-Entrepreneurs & Agri-Entrepreneurs into Agri-Business.
// description="GBR generates social and economic impact by transforming Agri-producers to become agri-entrepreneurs and agri-business into AAMC."
//           // description="GBR generates social and business impact by empowering Agri-producers to become Agri-entrepreneurs through transformational initiatives."

//           title="Why We Exist"
//           src={Farming}
//         />

//         <AboutSubSection
//           className1="aboutBgGrey"
//           className="rowReverseAbout--mod"
//           // description="GBR introducing Profit-generating Assets for Agri-Entrepreneurs."
//         description="GBR is transforming the farming landscape by introducing Profit-generating Assets (Agri-asset class) that empower agri-business and agri-entrepreneurs to thrive and prosper."
//           title="
//         Who We Are"
//           src={DigitalTrust}
//         />

//         <AboutSubSection
//           className="rowReverseAbout"
//           description="GBR care about Agri-business  who want to expand their line of business."
//           // description="GBR is dedicated to supporting Agri-producers who aspire to transition into agri-entrepreneurs."

//           title=" Whom We Care About"
//           src={CareImg}
//         />
//         <AboutSubSection
//           className1="aboutBgGrey"
//           className="rowReverseAbout--mod"
//           description={[
//             " We have introduced a novel income-generation scheme along with a mobile app and secure SAAS (Software as a Service) offerings, as outlined below:",
//           ]}
//           // description1={[
//           //   <strong>IMA:&nbsp;</strong>,
//           //   ` Investable Machinery Assets for agri-entrepreneurs.`,
//           // ]}
//           // description2={[
//           //   <strong>IAA:&nbsp;</strong>,
//           //   " Investable Agri Assets for agri-entrepreneurs and Agri-producers.",
//           // ]}

//           description3={[
//             <strong>AAMC:&nbsp;</strong>,
//             "We enable Agri-entrepreneurs to manage agri-assets in their own farm to improve production.",
//           ]}

//           description4={[
//             <strong>onMarketplace:&nbsp;</strong>,
//             "We provide organic D2C marketplace to Buy and Sell organic products",
//           ]}

//           description5={[
//             <strong>Social onMarketplace:&nbsp;</strong>,
//             "We provide a social B2B marketplace where businesses can interact with each other to make informed decisions about buying",
//           ]}

//           description6={[
//             <strong>Social iaaMarketplace:&nbsp;</strong>,
//             "We provide iaaMarketplace where investor can invest in to Agri-business and Agri-assets ",
//           ]}
//           // description4={[
//           //   <strong>Community:&nbsp;</strong>,

//           //   "We enable agri-entrepreneurs to manage the Organic certificate for the community (Producers). ",
//           // ]}
//           // description5={[
//           //   <strong> Billing Services:&nbsp;</strong>,
//           //   " Billing Services like invoice and purchase order management.",
//           // ]}
        
         

//           // description6={[
//           //   <strong> Ecosystem:&nbsp;</strong>,
//           //   "Ecosystem for agri-entrepreneurs to get advise , buy, sell and create trust.",
//           // ]}
//           // description7={[
//           //   <strong>Buy back services: &nbsp;</strong>,
//           //   "Buy back option for approved agri-entrepreneurs.",
//           // ]}
//           title="What We Do"
//           src={Trading}
//         />
//         <AboutSubSection
//           className="rowReverseAbout"
//           description="GBR Introducing Profit-generating Assets, Agri-Business Network, Agri asset management cloud and Production network for Agri-business."
//           title="How We Do It"
//           src={Blockchain}
//         />

//         <AboutSubSection
//           className1="aboutBgGrey"
//           className="rowReverseAbout--mod"
//           description16="Innovation and Value Creation"
//           description1="We continuously work on VALUE CREATION for Producers
//         through technology as an enabler. We encourage Innovation and Challenge the status quo."
//           description17="Passion"
//           description8="We are ambitious and believe in Teamwork
//        to achieve more when we collaborate and all work together."
//           description18="Courage and Trust"
//           description12=" We are willing to take risks and
//         Win-Win Partnerships With Our Business Partners. We view
//         Producers Service Providers and financial partners as trustworthy
//         allies in serving Agri-producers."
//           description19="Equality"
//           description20="We treat Producers and Partners with
//         respect, fairness, transparency and integrity. We embrace Diversity of experience, Culture and Opinions and belive in sharing as learning for everyone."
//           title="Our Culture & Values"
//           src={Values}
//         />
//         <AboutSubSection
//           className1="pb-0"
//           className="rowReverseAbout "
//           description="Our exceptional team, from dedicated employees to visionary leaders, fuels our success and propels us towards new heights. With open communication, trust, and collaboration at the core, we embrace innovation and achieve collective greatness. Together, we are shaping the future and proudly driving GBR forward."
//           title="
//         Our Team"
//           src={TeamImg}
//         />
//       </div>

//       <FooterComponent />
//     </>
//   );
// }
import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import Section1Component from "./Section1Component";
import DigitalTrust from "../../../src/images/gbrAbout/aboutimg2.webp";

import Trading from "../../../src/images/gbrAbout/aboutimg4.webp";
import Blockchain from "../../../src/images/gbrAbout/aboutimg5.webp";
import Farming from "../../../src/images/gbrAbout/aboutimg1.webp";
import Values from "../../../src/images/gbrAbout/aboutimg6.webp";
import CareImg from "../../../src/images/gbrAbout/aboutimg3.webp";
import TeamImg from "../../../src/images/gbrAbout/aboutimg7.webp";
import "react-awesome-slider/dist/styles.css";
import AboutBanner from "./AboutBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import PricingTabs from "../pricingTabs/PricingTabs";
import { Row, Col } from "react-bootstrap";
import test1 from "../../../src/images/ServiceIcons/CBN.svg";
import AboutListMain from "../AboutList/AboutListMain";
import GBRNetworkBusiness from "../GBRNetworkBusiness/GBRNetworkBusiness";

export default function AboutusComponent() {
  // var parse = require("html-react-parser");

  return (
    <>
      <HeaderComponent />
      <AboutBanner />
      <div className="about-main-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          //   description="GBR create Social & Business IMPACT by transforming the Agri-asset production process for Producers and Agribusiness.
          // "
          description="We enable agro-dealer to serve the local farmers"
          title="Why We Exist"
          //   descriptionTitle="
          // Enable Agribusiness to bring Commercial Farming For Everyone"
          src={Farming}
        />

        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          // description="GBR is a technology enabled Investable Asset company based in Bangalore that
          // provides new ways for Agribusiness and Producers to transform farming Industry.
          // "
          description="We are managed service provider that creates a digital trust in agri-input ecosystem.
        "
          title="
        Who We Are"
          src={DigitalTrust}
        />

        <AboutSubSection
          className="rowReverseAbout"
          description={`We care about Agro-dealers from micro to large enterprise.`}
          title=" Whom We Care About"
          src={CareImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="We Own, Operate and Manage ADN network to provide following services 
        "
        content={<AboutListMain/>}
          // description16={"ABN-SAAS :"}
          // description1={[
          //   <strong>ABN-Machinery:&nbsp;</strong>,
          //   ` Access machinery and support from approved machinery providers.`,
          // ]}
          // description2={[
          //   <strong>ABN-Broker[Global Logistic]:&nbsp;</strong>,
          //   " Most efficient supply chain service to buy from community and sell to market.",
          // ]}
          // description3={[
          //   <strong>ABN-Advisory:&nbsp;</strong>,
          //   " Browse the Advisors for legal organic food certificates.",
          // ]}
          // description7={[
          //   <strong>ABN-Export Online:&nbsp;</strong>,
          //   " Export Internationally through 3rd party platform or through GTN ",
          // ]}
          // description4={[
          //   <strong>ABN-Investment:&nbsp;</strong>,
          //   " Facilitate finance to MSME and farmers.",
          // ]}
          // description6={[
          //   <strong>ABN-Sell Online:&nbsp;</strong>,
          //   " Sell domestic in national market through India Mart, ONDC, or own GTN.",
          // ]}
          // description5={[
          //   <strong>ABN-Govt:&nbsp;</strong>,
          //   " Facilitate government schemes like asset distribution to producers through CBO.",
          // ]}
          // description21={
          //   [<strong> Billing Services:&nbsp;</strong>,
          //   " Billing Services like invoice and purchase order management.",]
          // }
          // description17={"CBN-SAAS Service:"}
          // description8={[
          //   <strong>CBN-Agri Asset : &nbsp;</strong>,
          //   " Facilitate Agri asset distribution service for producers .",
          // ]}
          // description9={[
          //   <strong>CBN-AgriMachinery: &nbsp;</strong>,
          //   "Facilitate machinery asset distribution service for producers. ",
          // ]}
          // description10={[
          //   <strong>CBN-AH/Clinic: &nbsp;</strong>,
          //   "Facilitate animal care through farmer advisors and nutrition distributors. ",
          // ]}
          // description11={[
          //   <strong>CBN-CBO: &nbsp;</strong>,
          //   "Manage own club of producers for local trade (buy & sell). ",
          // ]}
          // description18={"Production Network Service:"}
          // description12={[
          //   <strong>PN-PPN: &nbsp;</strong>,
          //   "Manage factory & distributed plant production through farmer franchise network & Own QA .",
          // ]}
          // description13={[
          //   <strong>PN-FPN: &nbsp;</strong>,
          //   " Manage field production in own farms through own operators and own QA",
          // ]}
          // description14={[
          //   <strong>PN-CF:&nbsp;</strong>,
          //   " Manage Agri-Assets production through contract farming.",
          // ]}
          // description15={[
          //   <strong>PN-AAMC:&nbsp;</strong>,
          //   "  Manage Agri-Assets through Farmer Franchises (Agri-entrepreneurs) network and their operators to manage the approved farms",
          // ]}

       
          
          title="What We Do"
          src={Trading}
        />
        {/* <Container>
      <div className="">
        <Row>
          <Col md={3}>
            <img src={test1}/>
            <h3>ABN-Machinery</h3>
            <p>Access machinery and support from approved machinery providers.</p>
          </Col>
          <Col md={3}>
            <img src={test1}/>
            <h3>ABN-Sell Online</h3>
            <p>Sell domestic market</p>
          </Col><Col md={3}>
            <img src={test1}/>
            <h3>ABN-Sell Online</h3>
            <p>Access machinery and support from approved machinery providers.</p>
          </Col><Col md={3}>
            <img src={test1}/>
            <h3>ABN-Sell Online</h3>
            <p>Sell domestic market</p>
          </Col><Col md={3}>
            <img src={test1}/>
            <h3>ABN-Sell Online</h3>
            <p>Sell domestic market</p>
          </Col>
        </Row>

      </div>
      </Container> */}
        <AboutSubSection
          className="rowReverseAbout"
          description={`We provide SAAS service for agro-dealers and partners in agri-input ecosystem`}
          title="How We Do It"
          src={Blockchain}
        />

        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          //   description="We continuously work on VALUE CREATION for Agri-MSMEs
          // through technology as an enabler | We treat MSMEs with
          // respect, fairness, transparency and integrity | We Practice
          // Win-Win Partnerships With Our Business Partners — We view
          // MSME Service Providers and financial partners as trustworthy
          // allies in serving MSMEs & Farmers | We believe in Teamwork:
          // We achieve more when we collaborate and all work together."
          description16="Innovation and Value Creation"
          description1="We continuously work on VALUE CREATION for brands and Farmers
        through technology as an enabler. We encourage Innovation and Challenge the status quo. "
          description17="Passion"
          description8="We are ambitious and believe in Teamwork
       to achieve more when we collaborate and all work together."
          title="Our Culture & Values."
          src={Values}
          description18="Courage and Trust"
          description12=" We are willing to take risks and 
        Win-Win Partnerships With Our Business Partners.We view
        Agribusiness Service Providers and financial partners as trustworthy
        allies in serving brands & Farmers."
          description19="Equality"
          description20="We treat brands and Partners with
        respect, fairness, transparency and integrity. We embrace Diversity of experience, Culture and Opinions and belive in sharing as learning for everyone."
        />
        <AboutSubSection
          className1="pb-0"
          className="rowReverseAbout "
          description="Our exceptional team, from dedicated employees to visionary leaders, fuels our success and propels us towards new heights. With open communication, trust, and collaboration at the core, we embrace innovation and achieve collective greatness. Together, we are shaping the future and proudly driving GBR Network forward.

        "
          title="
        Our Team"
          src={TeamImg}
        />
      </div>

      <GBRNetworkBusiness/>

      <FooterComponent />
    </>
  );
}
