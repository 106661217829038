import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import ForFarmerSection3 from "../ForFarmerSection3/ForFarmerSection3";
import InvestmentROIComponent from "../WebSite/InvestmentROI/InvestmentROIComponent";
import ForFarmerSection4 from "../ForFarmerSection4/ForFarmerSection4";
import "./ForFarmers.css";
import CTA from "../CTA/CTA";
import {Container} from "@material-ui/core"
import FArmerCTATab from "../FarmerCTATab/FArmerCTATab";
import TitleDescription from "../TitleDescription/TitleDescription";
import FarmerServices from "./FarmerServices";

export default function ForFarmers() {
  function redirectToFarmerAppPlayStore() {
    var appPackage = "com.instagram.android";
    var playStoreUrl = "market://details?id=" + appPackage;
    window.location.href = playStoreUrl;
  }
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="farmUpdateBanner text-white"
        bannerText={["Embark on your earning journey now by efficiently ", <br className="d-md-block d-none"></br>, "managing profit-generating assets"]}
        buttonText="Start Earning Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Grower");
        }}

        DlBtn ="true"
        dlLink="https://play.google.com/store/apps/details?id=com.ADNapp.aamcapp"
        DownloadText="Download App"
      />


      <div>
        <Container>
        <TitleDescription
        classname="mb-0 mx-md-4 mx-0"
        classname2 = "mb-0"
          title="Meet Our ADN Growers"
          description="ADN is a revolutionary initiative that transforms traditional agri-producers into certified growers, with each grower officially recognized and approved by ADN, ensuring the highest standards of agricultural excellence and quality.
          "
        />
        <FarmerServices />
        </Container>
       
        {/* <ForFarmerSection3 /> */}
        <ForFarmerSection4 />
        {/* <InvestmentROIComponent /> */}

        <FArmerCTATab/>
       
      </div>
      <FooterComponent />
    </>
  );
}
