import React from "react";
import HomeSubSection from "../HomeSubSection/HomeSubSection";

export default function AromaAssets() {
  return (
    <>
      {" "}
      <div className="pt-4" id="Lavender">
        <HomeSubSection
          className1="aboutBgGrey"
          className3="imagea1"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
      Lavender"
          description="ADN is a technology company based in Bangalore that
                    provides an alternate innovate options for agri-MSMEs
                    Founders to maximize profit [reduce procurement cost &
                    export] and raise easy investments through IAA.
                    "
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          onClick={() => {
            localStorage.setItem("abnUser", "AssetOEM");
          }}
        />
      </div>

      <div className="pt-4" id="LemonGrass">
        <HomeSubSection
          className3="imagea2"
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          descriptionTitle="
            Lemongrass"
          description="ADN is a technology company based in Bangalore that
                    provides an alternate innovate options for agri-MSMEs
                    Founders to maximize profit [reduce procurement cost &
                    export] and raise easy investments through IAA.
                    "
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          onClick={() => {
            localStorage.setItem("abnUser", "AssetOEM");
          }}
        />
      </div>

      <div className="pt-4" id="Peppermint">
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="imagea3"
          descriptionTitle="
            Peppermint"
          description="ADN is a technology company based in Bangalore that
                    provides an alternate innovate options for agri-MSMEs
                    Founders to maximize profit [reduce procurement cost &
                    export] and raise easy investments through IMA.
                    "
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          onClick={() => {
            localStorage.setItem("abnUser", "AssetOEM");
          }}
        />
      </div>

      <div className="pt-4" id="OilMachinery">
        <HomeSubSection
          className3="imagea4"
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          descriptionTitle="
             Machinery"
          description="ADN is a technology company based in Bangalore that
                    provides an alternate innovate options for agri-MSMEs
                    Founders to maximize profit [reduce procurement cost &
                    export] and raise easy investments through IAA.
                    "
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          onClick={() => {
            localStorage.setItem("abnUser", "AssetOEM");
          }}
        />
      </div>
      {/* <div  className="pt-4" id="Cow">
        <HomeSubSection
          className="rowReverseAbout"
          description="ADN Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
      "
          descriptionTitle="Cow"
          src={CowImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        />
      </div> */}
    </>
  );
}
