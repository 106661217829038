import React from 'react'
import StepCards from '../stepCards/StepCards'
import step1 from "../../images/ae-journey/ae1.png";
import step2 from "../../images/ae-journey/ae2.png";

import step3 from "../../images/step2.png";
import step4 from "../../images/ae-journey/ae3.png";
import step5 from "../../images/ae-journey/ae4.png";
import img5 from "../../images/ae-journey/ae5.png";
import img1 from "../../images/optionalChoice.png";
import { Row, Col } from "react-bootstrap";

export default function AgriEnrepenurJourney() {
    const stepCardContent = [
      {
        title: "Step 1",
        bodycopy: " Contact a ADN agent to initiate an account or request the creation of an account.",
        img: step1,
      },
      {
        title: "Step 2",
        bodycopy: "Provide the required identification and address proof documents to finalize your account creation.",
        img: step2,
      },
      {
        title: "Step 3",
        bodycopy: "Download the application and log in to your account.",
        img: step3,
      },
        {
          title: "Step 4",
          bodycopy:
            "Give request for IAA/IMA.",
          img: step4,
        },
        {
          title: "Step 5",
          bodycopy:
          "Distribute assets to Growers and have them manage those assets and do Contract manufacturing .",
          img: img5,
        },
        {
            title: "Step 6",
            bodycopy:
              "Share report with ADN farming.",
            img: step5,
          },
      ];
  return (
    <>
    <h2 className="text-center container-title mb-4">
    Production Manager Journey
  </h2>
    <div className='section3BodyContainer justify-content-md-center p-0'>
         
    {stepCardContent.map((e) =>{
               return (
                <StepCards
                  src={e.img}
                  stepTitle={e.title}
                  stepContent={e.bodycopy}
                />
              );
        })}    </div>
        </>
  )
}
