import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./AgriStackComponent.css";
import Button from "@material-ui/core/Button";
import "react-awesome-slider/dist/styles.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import ecoSystemViewImg from "../../images/product.png";
import systemViewImg from "../../images/digital.png";
import dataDrivenViewImg from "../../images/monitor.png";
import iobDrivenViewImg from "../../images/cloud-storage.png";
import blockchainTransactionViewImg from "../../images/block.png";
import appDrivenViewImg from "../../images/mobile-app.png";
import ServiceTypes from "../ServiceTypes/ServiceTypes";
import TradeStack from "../../images/profit.png";
import AgriTechStack from "../../images/agriculture.png";

export default class AgriStackComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {} // Keval -> ToDo call function to show some statistics
  componentDidUpdate(prevProps) {
    if (prevProps.sliderRef === this.props.sliderRef) return;
    setTimeout(this.showSliderContent, 300);
    if (this.state.selectedSlide === this.props.selectedSlide) return;
    this.setState({ selectedSlide: this.props.selectedSlide });
  }
  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  onTransitionEnd(value) {
    if (this.state.selectedSlide === value.currentIndex) return;
    this.setState({ selectedSlide: value.currentIndex });
  }
  showSliderContent = () => {
    this.sliderContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  render() {
    return (
      <>
        <HeaderComponent />

        <div
          maxwidth={12}
          className="businessPartnerContainer agristackContainer"
        >
          <Container maxWidth="sm" className="gridSection ">
            <Grid
              container={true}
              alignItems="center"
              spacing={1}
              direction="row"
            >
              <Grid
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#ecoSystemView">
                  <img
                    src={ecoSystemViewImg}
                    alt="ecoSystem & Mobile Apps View "
                  />
                  <h4>Track And Trace Stack</h4>
                </a>
              </Grid>
              <Grid
                id="systemView_link"
                className="hoverEffect mb-3"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#systemView">
                  <img src={systemViewImg} alt="Digital Twin" />
                  <h4>Digital Twin Stack</h4>
                </a>
              </Grid>
              <Grid
                id="dataDrivenView_link"
                className="hoverEffect mb-3"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#dataDrivenView">
                  <img src={dataDrivenViewImg} alt="Data Driven" />
                  <h4>Data Driven Stack</h4>
                </a>
              </Grid>
              <Grid
                id="iobDrivenView_link"
                className="hoverEffect mb-md-0 mb-3 "
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#iobDrivenView">
                  <img src={iobDrivenViewImg} alt="IoT/IoB" />
                  <h4>IoT/IoB Driven Stack</h4>
                </a>
              </Grid>
              <Grid
                className="hoverEffect "
                id="blockchainTransactionView_link"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#blockchainTransactionView">
                  <img src={blockchainTransactionViewImg} alt="Blockchain" />
                  <h4>Blockchain Stack</h4>
                </a>
              </Grid>
              <Grid
                id="appDrivenView_link"
                className="hoverEffect  "
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#registrystack">
                  {" "}
                  <img src={appDrivenViewImg} alt=" Mobile apps" />
                  <h4>Registry Stack</h4>
                </a>
              </Grid>

              <Grid
                id="appDrivenView_link"
                className="hoverEffect  "
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#tradestack">
                  {" "}
                  <img src={TradeStack} alt=" Mobile apps" />
                  <h4>Trade Stack</h4>
                </a>
              </Grid>

              <Grid
                id="appDrivenView_link"
                className="hoverEffect  "
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a href="#agritech">
                  {" "}
                  <img src={AgriTechStack} alt=" Mobile apps" />
                  <h4>Agri-Tech Stack</h4>
                </a>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container className="section2Container">
          <h4 className="container-title">Why Technology?</h4>
          <Grid spacing={0} container={true} justifyContent="center">
            <Grid item md={8} sm={8} xs={12}>
              <p className="container-para">
                Harnessing The Power Of Right Technology To Enable Digital
                Transformation in Farming Sector. Technological innovations in
                field have greatly shaped that field throughout time. So the
                need in Farming vertical. From the creation of the flow to the
                sensor driven precision farming equipment, humans always have
                developed new ways through Technology to make farming more
                efficient and grow more safe and secure food. We constantly need
                to work with Technology to find new ways to cultivate
                Agriculture and Animal Husbandary products.
              </p>
            </Grid>
          </Grid>

          <Container
            maxWidth="lg"
            className="fullWidthContainer technology-section"
          >
            <ServiceTypes
              href="http://54.185.221.117:8902/v2/api-docs/"
              alt="Icon"
              id="ecoSystemView"
              src={ecoSystemViewImg}
              title="Track and Trace"
              description1="The ADN Interaction Software Platform comprises a suite of
            transactions between different actors of Farming ecosystem
            .These interactions simplifies the user experience through secure
            App Driven Architecture."
              description2="App Driven | Ecosystem Driven | Secure Access Driven | Digital
            Trust Driven"
            />

            <ServiceTypes
              href="http://54.185.221.117:8900/v2/api-docs/"
              alt="Icon"
              id="systemView"
              src={systemViewImg}
              title="Digital Twin Experience"
              description1="ADN Innovation Platform has many Sub-system . These Subsystems
            acts as independent Innovation Enablers . Digital Twin enabler
            provides phycial and real experience of Farming Virtually."
              description2="Farming Assets Driven | Sensing Device Driven | Solution Driven
            | Site Driven"
              target="true"
            />

            <ServiceTypes
              href="http://54.185.221.117:8901/v2/api-docs/"
              alt="Icon"
              id="dataDrivenView"
              src={dataDrivenViewImg}
              title="Data Driven Experience"
              description1="Unified Farming Assets Data Management for different Farming
            Assets. This Innovation Enabler provides growing storage for
            Data Analytic , Data Science and tools to develop AI/ML
            Algorithms along with ML Ops mechanism to provision the Models
            at the platform edge."
              description2="Assets Event Data | Quality Control Data | Environmental Data |
          Positioning Data | Trading Transactional Data | Assets Images"
            />

            <ServiceTypes
              href="http://35.92.73.23:5555/v2/api-docs/"
              alt="Icon"
              id="iobDrivenView"
              src={iobDrivenViewImg}
              title="IOB Driven Experience"
              description1=" Device-cloud Innovation Subsystem is the base to build IOB
            Subsystem. This base securely routes the Farming Assets and
            Farming/ Food Processors data to many other interconnecting
            subsystems of the platform such as IOB , Data Management,
            Digital Twin simultaneously. Being able to have Farming Asset
            and Farming Ecosystem Actor to connect to Device cloud is key
            for ADN IOB Stack solution."
              description2="Tags | Readers | Radio Stacks| Connected Actors"
              target="true"
            />

            <ServiceTypes
              href="http://bc.gbrservice.com/v2/api-docs/"
              alt="Icon"
              id="blockchainTransactionView"
              src={blockchainTransactionViewImg}
              title="BlockChain Transactions Experience"
              description1={[
                "Traceability  to Provenance to create the Brand.",
                <br></br>,
                `Growing Assets Events - Provenance.`,
                <br></br>,
                `Asset Traceability .`,
                <br></br>,
                `Trading and Delivery Events Provenance.`,
              ]}
              description2="Provenance | Immutability | Decentralization
            "
              target="true"
            />

            <ServiceTypes
              href="http://35.92.73.23:8081/v2/api-docs/"
              alt="Icon"
              id="registrystack"
              src={appDrivenViewImg}
              title="Registry Experience"
              description1=" Mobile App is the only todays tool for experience and brand
            building."
              description3="Experience to all actors including technology providers through
            the apps"
              description2="Investor App View | Grower App View | Buyer App View |
            Transporter App View | BusinessPartner App View"
              target="true"
            />

            <ServiceTypes
              href="http://35.92.73.23:8081/v2/api-docs/"
              alt="Icon"
              id="tradestack"
              src={TradeStack}
              title="Trade Experience"
              description1=" Mobile App is the only todays tool for experience and brand
            building."
              description3="Experience to all actors including technology providers through
            the apps"
              description2="Investor App View | Grower App View | Buyer App View |
            Transporter App View | BusinessPartner App View"
              target="true"
            />

            <ServiceTypes
              href="http://35.92.73.23:8081/v2/api-docs/"
              alt="Icon"
              id="agritech"
              src={AgriTechStack}
              title="Agri-Tech Experience"
              description1=" Mobile App is the only todays tool for experience and brand
            building."
              description3="Experience to all actors including technology providers through
            the apps"
              description2="Investor App View | Grower App View | Buyer App View |
            Transporter App View | BusinessPartner App View"
              target="true"
            />
            {/* <Grid
            container={true}
            id="ecoSystemView"
            spacing={0}
            className="fullWidthContainer sectionGrey"
            justifyContent="center"
          >
            <Grid item md={8} sm={8} xs={12} className="contentContainer" >
              <img
                src={ecoSystemViewImg}
                alt="Project Ideation"
                className="section-icon"
              />
              <h4 className="container-heading1"></h4>
              <p className="container-para">
                The ADN Interaction Software Platform comprises a suite of
                transactions between different actors of Farming ecosystem
                .These interactions simplifies the user exerinece through secure
                App Driven Architecture.
                <br /> <br />
                
              </p>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod"
                type="button"
              >
                <a href="/ComingSoon">REQUEST For APIs</a>
              </Button>
              <p className="section-inner-border">_________</p>
            </Grid>
          </Grid>

          <Grid
            container={true}
            id="systemView"
            spacing={0}
            className="fullWidthContainer"
            justifyContent="center"
          >
            <Grid item md={8} sm={8} xs={12} className="contentContainer">
              <img
                src={systemViewImg}
                alt="Project Ideation"
                className="section-icon"
              />
              <h4 className="container-heading1">Digital Twin Experience</h4>
              <p className="container-para">
                ADN Innovation Platform has many Sub-system . These Subsystems
                acts as independent Innovation Enablers . Digital Twin enabler
                provides phycial and real experince of Farming Virtually.
              </p>
              <p className="container-para">
                {" "}
                Farming Assets Driven | Sensing Device Driven | Solution Driven
                | Site Driven
              </p>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod"
                type="button"
              >
                <a href="/ComingSoon">REQUEST For APIs</a>
              </Button>
              <p className="section-inner-border">_________</p>
            </Grid>
          </Grid>

          <Grid
            container={true}
            id="dataDrivenView"
            spacing={0}
            className="fullWidthContainer sectionGrey"
            justifyContent="center"
          >
            <Grid item md={8} sm={8} xs={12} className="contentContainer">
              <img
                src={dataDrivenViewImg}
                alt="Project Ideation"
                className="section-icon"
              />
              <h4 className="container-heading1">Data Driven Experience</h4>
              <p className="container-para">
                Unified Farming Assets Data Management for different Farming
                Assets. This Innovation Enabler provides growing storage for
                Data Analytics , Data Science and tools to develop AI/ML
                Algorithms along with ML Ops mechanism to provision the Models
                at the platform edge.
              </p>
              <p className="container-para">
                Assets Event Data | Quality Control Data | Environmental Data|
                <br /> <br />
              Positioning Data | Trading Transactional Data | Assets Images
              </p>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod"
                type="button"
              >
                <a href="/ComingSoon">REQUEST For APIs</a>
              </Button>
              <p className="section-inner-border">_________</p>
            </Grid>
          </Grid>
          <Grid
            container={true}
            id="iobDrivenView"
            spacing={0}
            className="fullWidthContainer"
            justifyContent="center"
          >
            <Grid item md={8} sm={8} xs={12} className="contentContainer">
              <img
                src={iobDrivenViewImg}
                alt="Project Ideation"
                className="section-icon"
              />
              <h4 className="container-heading1">IOB Driven Experience</h4>
              <p className="container-para">
                Device-cloud Innovation Subsystem is the base to build IOB
                Subsystem. This base securely routes the Farming Assets and
                Farming/ Food Processors data to many other interconnecting
                subsystems of the platform such as IOB , Data Management,
                Digital Twin simultaneously. Being able to have Farming Asset
                and Farming Ecosystem Actor to connect to Device cloud is key
                for ADN IOB Stack solution.
              </p>
              <p className="container-para">
                Tags | Readers | Radio Stacks| Connected Actors
              </p>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod"
                type="button"
              >
                <a href="/ComingSoon">REQUEST For APIs</a>
              </Button>
              <p className="section-inner-border">_________</p>
            </Grid>
          </Grid>
          <Grid
            container={true}
            id="blockchainTransactionView"
            spacing={0}
            className="fullWidthContainer sectionGrey"
            justifyContent="center"
          >
            <Grid item md={8} sm={8} xs={12} className="contentContainer">
              <img
                src={blockchainTransactionViewImg}
                alt="Project Ideation"
                className="section-icon"
              />
              <h4 className="container-heading1">BlockChain Transactions Experience</h4>
              <p className="container-para">Tracebility to Provenance to create the Brand.</p>
              <ul className="lists">
                <li className="container-para">Growing Assets Events - Provenance.</li>
                <li className="container-para">Asset Tracebility.</li>
                <li className="container-para">Trading and Delivery Events Provenance.</li>
              </ul>
              <p className="container-para">
                Provenance | Immutability | Decentalization
              </p>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod"
                type="button"
              >
                <a href="http://bc.gbrservice.com/v2/api-docs/" target="_blank">REQUEST For APIs</a>
              </Button>
              <p className="section-inner-border">_________</p>
            </Grid>
          </Grid>
          <Grid
            container={true}
            id="appDrivenView"
            spacing={0}
            className="fullWidthContainer"
            justifyContent="center"
          >
            <Grid item md={8} sm={8} xs={12} className="contentContainer">
              <img
                src={appDrivenViewImg}
                alt="Project Ideation"
                className="section-icon"
              />
              <h4 className="container-heading1">Mobile Apps Experience</h4>
              <p className="container-para">
                Mobile App is the only todays tool for experince and brand
                building
              </p>
              <p className="container-para">
                Experince to all actors including technology providers through
                the apps
                <br /> <br />
                Investor App View | Grower App View | Buyer App View |
                Transporter App View | BusinessPartner App View
              </p>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod"
                type="button"
              >
                <a href="/ComingSoon">REQUEST For APIs</a>
              </Button>
              <p className="section-inner-border">_________</p>
            </Grid>
          </Grid> */}
          </Container>
        </Container>

        <FooterComponent />
      </>
    );
  }
}
