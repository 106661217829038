import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./AssetService.css";
import IAATab from "../WebSite/IAATab/IAATab";
import InvestmentROIComponent from "../WebSite/InvestmentROI/InvestmentROIComponent";
import FArmerCTATab from "../FarmerCTATab/FArmerCTATab";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import CTA from "../CTA/CTA";
import { Container } from "react-bootstrap";
export default function AssetService() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner className="assetService" bannerText={["Agriculture as a New Investment class"]} />
<div className="subSectionWrapper">
<h2 className="container-title text-center">Profit-generating Services</h2>

      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="moneyMakingSupport1"
        descriptionTitle="
        Monetize Farming Capacity "
      description="ADN's Monetize Farming Capacity service is a pivotal offering, enabling agri-entrepreneurs to significantly augment their income through contract farming opportunities. This innovative approach empowers them to unlock their full agricultural potential while promoting sustainable farming practices."

      />
            <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="moneyMakingSupport2"
        descriptionTitle="
        Monetize Storage Capacity"
        description="Monetize Storage Capacity is a valuable service provided by ADN, allowing agri-entrepreneurs to generate additional income by sharing their storage facilities. This innovative approach enables them to optimize their storage space utilization while boosting their financial resources."
        />
    {/* <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="processingSupport3"
        description="ADN's Monetize Processing Capacity service empowers Agri-producers to optimize their equipment, producing top-quality goods for both local and global brands. By connecting skilled professionals with customers, we foster a mutually beneficial ecosystem that not only boosts revenue but also encourages productive collaborations within the agriculture and production industry.
        "
        descriptionTitle="
        Monetize Processing Capacity"
      /> */}
      <div id="MoneyMakingAssets" className="subSectionWrapper pb-0">
        <IAATab />
      </div>
      {/* <InvestmentROIComponent /> */}
      <Container className=" subSectionWrapper">
          <CTA
            data="present"
            href="/auth/3/signin"
            heading="Want to become an Agri-Entrepreneur to create Trusted brand and earn extra income"
            btnText="Register Now"
          />
        </Container>
        </div>
      <FooterComponent />
    </>
  );
}
