import React from 'react';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import "./BillingService.css";
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';

export default function BillingService() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="asset-market-bg" bannerText ="Billing Service"/>

    <div className="main-wrapper2">
        <div className="subSectionWrapper">
          <HomeSubSection
            className3="advisory-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="Buyer Account Management"
            description="Buyer Account Management is a service provided by ADN, empowering users to streamline their procurement processes with advanced RFQ (Request for Quotation) and PO (Purchase Order) management tools. With our intuitive platform, users can effortlessly create RFQs and POs, ensuring accuracy and efficiency in procurement. Simplify your workflow today and experience seamless procurement management with ADN."
         
          />

          <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards"
            className3="fieldSupportService1"
            description="
            Seller Account Management, a comprehensive solution provided by ADN, offers powerful quotation and invoice management tools. Our platform empowers sellers to effortlessly generate and oversee quotations and invoices, ensuring precision and efficacy in sales transactions. Take control of your sales workflow today and discover the seamless sales management experience offered by ADN."
            descriptionTitle="Seller Account Management"
          />
          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards"
            className3="fieldSupportService2"
            descriptionTitle="Transport Management System"
            description="
            Transport Management System, brought to you by ADN, provides robust tools for efficient lorry receipt, delivery receipt, and delivery challan creation. Seamlessly generate and manage these documents to streamline your transportation processes. Experience enhanced control and efficiency in managing your logistics with ADN's Transport Management System."            
          />
         
        </div>
      </div>
      <FooterComponent/>
    </>
  )
}
