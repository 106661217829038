import React from 'react'
import AboutList from './AboutList'

export default function ABNSAAS() {
    const data=[
      //  "Track & Trace:  Track & Trace is a solution provided by GBR Network to agribusiness for monitoring and tracing the supply chain of agricultural products.",
       "End to End Traceability for agri-inputs :  End-to-End Traceability is a solution provided by ADN Network to agro-dealers for comprehensive monitoring and tracking of the entire supply chain of agricultural products. ",
      
       
    ]
  return (
   <AboutList title="Solutions" contentlist={data}/>
  )
}