import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import "./BusinessDiversificationService.css";




export default function BusinessDiversificationService() {

 
  return (
    
    <>

    <HeaderComponent/>
    <UpdatesBanner
        className="business-growth-bg"
        bannerText={["Business Diversification Service"]}
      />
      <div className="subSectionWrapper" >
      {/* <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService5"
        descriptionTitle="
      Loan"
      description="GBR Farming facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, GBR Farming is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."

      /> */}
 <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
  
  description="Agro Dealer Network offers a business diversification service that enables Agro Dealers to transition into Community-Based Organizations (CBOs). This service helps Agro Dealers expand their operations to better support local communities, providing a wider range of services and fostering community development. By becoming CBOs, Agro Dealers can enhance their impact on agricultural productivity and local economies. Agri-business Network provides the necessary expertise and resources to ensure a smooth and successful transition."
  href2="auth/3/signup"
  buttoncont2="Register"
  onClick={() => {
    localStorage.setItem("userType", "CBO");
  }}
  />      
      </div>
    <FooterComponent/>
    </>
  )
}
