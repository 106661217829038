import React from "react";
import FarmerImg from "../../../images/gbrPN/PNImg1.webp";
import OperatorImg from "../../../images/gbrPN/PNImg3.webp";
import QAImg from "../../../images/gbrPN/PNImg2.webp";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import FarmersFranchiseImg from "../../../images/gbrPN/PNImg5.webp"
import ExecutiveImg from "../../../images/gbrPN/PNImg4.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import HeaderComponent from "../Header/HeaderComponent";
import NetworkBanner from "../NetworkBanner/NetworkBanner";

import FooterComponent from "../Footer/FooterComponent";
import IAABenefit from "../IAABenefit/IAABenefit";
import IAAManagement from "../IAAManagement/IAAManagement";
import PNServices from "../../OurServices/PNServices";
import ExploreTechnologySection1 from "../../ExploreTechnology/ExploreTechnologySection1";
import MoneyMakingAssets from "../../ForFarmers/MoneyMakingAssets";
import AAMCNetworkSection1 from "./AAMCNetworkSection1";
import AAMCNetworkServices from "./AAMCNetworkServices";

export default function AAMCNetwork() {
  return (
    <>
      <HeaderComponent />
      <NetworkBanner                descriptionTitle="Agri Asset Management Cloud"
bannerTitle="Agri Asset Management Cloud for Digital Transformation in Farming sector" bodycopy="Job creation | Digital transformation | Brand differentiation | Investable asset management | Organic certification" className="pnbg" />
      <div className="subSectionWrapper pb-0">
      <AAMCNetworkSection1       listItems="true"        description="AAMC is a technology cloud platform for Agri-Entrepreneur to enable the digital transformation with the following benefits"
/>
      </div>
      {/* <AAMCNetworkServices title="Agri Asset Management Cloud Services"/> */}
      <MoneyMakingAssets />

      {/* <IAABenefit />
      <IAAManagement /> */}

<div className="main-wrapper2">
            <div className="subSectionWrapper ">
        <h2 className="container-title text-center mt-4">
          AAMC Network Operator{" "}
        </h2>

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards"
          className3="agribusiness-img"
          description="A farm enterprise, also known as a farming enterprise, is an agribusiness that is primarily engaged in agricultural activities. This includes the cultivation of crops, the raising of livestock, and other related farming practices. We help farm enterprises to become Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.


              "
          descriptionTitle="
          Farm Enterprise

          "
          src={ProductionManagerImg}
          
        />
</div>
</div>

      <div className="network-wrapper">
        {/* <h2 className="container-title text-center mt-4">Rural-Entrepreneur Team </h2> */}
        <h2 className="container-title text-center mt-4">AAMC Network Members </h2>
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="ADN's growers, meticulously vetted through a rigorous verification process, form the foundation of their agricultural network. Their commitment to quality and sustainable practices ensures the reliability and integrity of the produce within ADN's ecosystem, earning trust from partners and consumers alike."
          descriptionTitle="
          Agri-Entrepreneur"
          src={FarmerImg}
         
        />

        {/* <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod"
        descriptionTitle="
        Agri-Storage Entrepreneur"
        description="Farmer franchises are the backbone of our agricultural network, serving as the linchpin in our ecosystem's success. These franchisees are responsible not only for land cultivation but also for managing and overseeing our network of verified growers and valuable assets. Their role is pivotal in maintaining the integrity and productivity of our agricultural operations.
      "
        src={FarmersFranchiseImg}
        // href2="auth/3/signup"
        // buttoncont2="Register"
        // href1="auth/3/signin"
        // buttoncont1="Sign In"

        // href7='http://cbn.gbrapp.com/'
        href2="auth/3/signin"
        buttoncont2="Sign in"
        buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "FF");
        }}              href7="/auth/3/signup"
      /> */}

<HomeSubSection
          className1="aboutBgGrey"
          className3="image12"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
              QA Role"
          description="ADN is a technology company based in Bangalore that
      provides an alternate innovate options for Agribusiness
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={QAImg}
       
        />
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image13"
          description="ADN Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agribusiness
              "
          descriptionTitle="
              Operator Role"
          src={OperatorImg}
        
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image14"
          descriptionTitle="
              Executive Role"
          description="ADN is a technology company based in Bangalore that
      provides an alternate innovate options for agribusiness
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={ExecutiveImg}
       
        />
{/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="ADN's growers, meticulously vetted through a rigorous verification process, form the foundation of their agricultural network. Their commitment to quality and sustainable practices ensures the reliability and integrity of the produce within ADN's ecosystem, earning trust from partners and consumers alike."
          descriptionTitle="
          Agri-Contract Manufacturer"
          src={FarmerImg}
          href2="auth/3/signin"
          buttoncont2="Sign in"
          buttoncont7='Sign up'

          onClick={() => {
            localStorage.setItem("userType", "Production-Manager");
          }}              href7="/auth/3/signup"
        />

        <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod"
        descriptionTitle="
        Agri-Retailers"
        description="Farmer franchises are the backbone of our agricultural network, serving as the linchpin in our ecosystem's success. These franchisees are responsible not only for land cultivation but also for managing and overseeing our network of verified growers and valuable assets. Their role is pivotal in maintaining the integrity and productivity of our agricultural operations.
      "
        src={FarmersFranchiseImg}
        // href2="auth/3/signup"
        // buttoncont2="Register"
        // href1="auth/3/signin"
        // buttoncont1="Sign In"

        // href7='http://cbn.gbrapp.com/'
        href2="auth/3/signin"
        buttoncont2="Sign in"
        buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "FF");
        }}              href7="/auth/3/signup"
      />


<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="ADN's growers, meticulously vetted through a rigorous verification process, form the foundation of their agricultural network. Their commitment to quality and sustainable practices ensures the reliability and integrity of the produce within ADN's ecosystem, earning trust from partners and consumers alike."
          descriptionTitle="
          Agri-Transporter"
          src={FarmerImg}
          href2="auth/3/signin"
          buttoncont2="Sign in"
          buttoncont7='Sign up'

          onClick={() => {
            localStorage.setItem("userType", "Driver");
          }}              href7="/auth/3/signup"
        /> */}






        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image34"
          description="Production managers within our system play a multifaceted and essential role. They are responsible for supervising contract manufacturing, ensuring efficient cultivation practices, and managing a network of growers and valuable assets, all crucial components of our operation's success.
              "
          descriptionTitle="
              Production Manager Role"
          src={ProductionManagerImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
        //   href4='http://cbn.gbrapp.com/'
        //   buttoncont1='Sign In'

        // href7='http://cbn.gbrapp.com/'
        href2="auth/3/signin"
        buttoncont2="Sign in"
        buttoncont7='Sign up'

        onClick={() => {
          localStorage.setItem("userType", "Production-Manager");
        }}              href7="/auth/3/signup"
        /> */}



      </div>
      <FooterComponent />
    </>
  );
}
