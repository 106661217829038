import React from "react";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import StorageMachImg from "../../../images/gbrHome/storageMachine.webp";
import ProcessingMachImg from "../../../images/gbrHome/processingMachine.webp";
import FillingMachImg from "../../../images/gbrHome/fillilingMachine.webp";

export default function InvestableMachinery() {
  return (
    <>
      {/* <div className="pt-4" id="ProcessingMachinery">
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image8"
          descriptionTitle="
            Processing Machinery"
          description="

          GBR farming includes processing machinery as an asset type, empowering agri-producers to efficiently manage their agricultural processes and improve productivity on the farm.
                    "
          src={ProcessingMachImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        />
      </div> */}
      <div className="pt-4" id="StorageMachinery">
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image9"
          description="ADN Network also encompasses storage machinery as an asset type, enabling agri-producers to effectively manage and preserve their agricultural products on-site. This integration enhances storage capabilities and reduces the risk of spoilage or loss. "
          descriptionTitle=" Storage Machinery"
          src={StorageMachImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        />
      </div>

      {/* <div className="pt-4" id="FillingMachinery">
        <HomeSubSection
          className3="image10"
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
            Filling Machinery"
          description="GBR farming incorporates filling machinery as an asset type, allowing agri-producers to efficiently handle the packaging and filling of agricultural products. This integration streamlines the processing and packaging stages, enhancing the overall efficiency of farming operations.
                    "
          src={FillingMachImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        />
      </div> */}
    </>
  );
}
