import React from 'react';
import Icon1 from "../../images/ServiceIcons/machinery.svg";
import Icon2 from "../../images/ServiceIcons/broker.svg";

import Icon3 from "../../images/ServiceIcons/advisor.svg";
import Icon4 from "../../images/ServiceIcons/investment.svg";
import Icon5 from "../../images/ServiceIcons/government.svg";
import Icon6 from "../../images/ServiceIcons/SellOnline.svg";
import Icon7 from "../../images/ServiceIcons/Export.svg";
import Icon8 from "../../images/ServiceIcons/supply-chain-management.svg"
import OurServiceCardContainer from './OurServiceCardContainer';




export default function ABNServices() {
    const ABNServices = [
   
        {
          image: Icon1,
          title: "Access to machinery & other agri-inputs ",
          bodycopy:
            "Access to machinery and other agri-inputs from approved manufacturers is a key feature of the agro dealer network, ensuring reliable and diverse product offerings for agricultural operations.",
            //href:"#ADN-Advertisement"
        },
       


        {
          image: Icon2,
          title: " Own ARN Newtwork ",
          bodycopy:
            "Own ARN of the ADN allows agro dealers to own, operate, and manage a network of agri-retailers. They can also oversee their field representatives and agents, enhancing control and efficiency in their distribution channels.",
          // href: "#ADN-Transporter"
        },

        // {
        //   image: Icon8,
        //   title: " ADN-VCN",
        //   bodycopy:
        //     "Most efficient value chain service to procure raw-material, create product and distribute.",
        //     // href: "#ADN-Transporter"
        // },

        // {
        //   image: Icon3,
        //   title: "ADN-Advisory ",
        //   bodycopy:
        //     "         Browse the Advisors for legal organic food certificates. ",
        //     // href:"ADN-Advisory"
        // },
     


        {
            image: Icon4,
            title: "  ADN-Investment ",
            bodycopy:
              "Facilitate finance to retailers, farmers and collectives.",
              // href:"#ADN-Investor"
          },
          // {
            


          //   image: Icon5,
          //   title: " ADN-Govt ",
          //   bodycopy:
          //     "Facilitate government schemes like asset distribution to producers through CBO.",
          //     // href:"#ADN-Govt"
          // },
         


          // {
          //   image: Icon6,
          //   title: " ADN-Sell Online ",
          //   bodycopy:
          //     "Sell domestic in national market through India Mart, ONDC, or own GTN.",
          //   // href:"#ADN-Tender"
          // },
          


          // {
          //   image: Icon7,
          //   title: "ADN-Export Online ",
          //   bodycopy:
          //     "Export Internationally through 3rd party platform or through GTN.",
          //     // href:"#ADN-Tender"
          // },
          
          // {
          //   image: Icon7,
          //   title: "Supply Chain Management ",
          //   bodycopy:
          //     "Streamline the movement of products from production to distribution, ensuring timely delivery and reducing wastage. ",
          // },
          // {
          //     image: Icon7,
          //     title: "Quality Control",
          //     bodycopy:
          //       "Implement measures to ensure products meet regulatory standards and are safe for consumption or use.",
          //   },
      
            {
              image: Icon7,
              title: "Plant and Animal Health Monitoring",
              bodycopy:
                "Track growth metrics, diseases, treatments, and vaccinations to monitor the health of plants and animals.",
            },
            {
              image: Icon7,
              title: "Compliance Management",
              bodycopy:
                "Ensure compliance with regulations related to agri-input product safety, labeling to enable environmental sustainability.",
            },
          //   {
          //     image: Icon7,
          //     title: "Customer Relationship Management (CRM)",
          //     bodycopy:
          //       "Provide customers with access to product information, orders, deliveries, and address inquiries promptly.",
          //   },
          //   {
          //     image: Icon7,
          //     title: "Product Differentiation",
          //     bodycopy:
          //       "Highlight unique features like lab certification, sustainable farming practices, or nutritional value to attract customers.",
          //   },
            
      
        
      
      
      
      
          //   {
          //     image: Icon7,
          //     title: "Asset Information",
          //     bodycopy:
          //       "  Access detailed information about each asset, including specifications, maintenance requirements, and usage details.",
          //   },
          //   {
          //     image: Icon7,
          //     title: " Real-Time Location Monitoring",
          //     bodycopy:
          //       " Monitor the live location of assets in real-time, ensuring better visibility and control over their movements.",
          //   },
          //     {
          //     image: Icon7,
          //     title: "Environmental Monitoring",
          //     bodycopy:
          //       "  Gain insights into the environment surrounding each asset, facilitating proactive measures to optimize conditions for better performance.",
          //   },
          //  {
          //     image: Icon7,
          //     title: "Environmental Monitoring",
          //     bodycopy:
          //       "   Monitor environmental conditions at each site in real-time, ensuring optimal conditions for agricultural activities.",
          //   },{
          //     image: Icon7,
          //     title: "Issue Tracking",
          //     bodycopy:
          //       "   Track and manage issues encountered at each site, facilitating prompt resolution and minimizing disruptions to operations.",
          //   },{
          //     image: Icon7,
          //     title: " Site Operations",
          //     bodycopy:
          //       "   Perform various operations on sites, including updating site information, deleting outdated sites, providing feedback, and reporting issues for resolution.",
          //   },{
          //     image: Icon7,
          //     title: " Daily Reports",
          //     bodycopy:
          //       "  Generate daily reports summarizing site activities, performance metrics, and any notable events, facilitating data-driven decision-making.",
          //   },{
          //     image: Icon7,
          //     title: "Site Alarms",
          //     bodycopy:
          //       "   Set up alarms and notifications for critical events or deviations from desired conditions, allowing for timely interventions and preventive measures.",
          //   },{
          //     image: Icon7,
          //     title: "Group Nutrition",
          //     bodycopy:
          //       "   Manage nutrition plans for groups of sites, ensuring consistent and optimized nutrition for crops or livestock across multiple locations.",
          //   },
          //   {
          //     image: Icon7,
          //     title: "Site Attendance",
          //     bodycopy:
          //       "    Track attendance and activities at each site, providing insights into workforce productivity and resource utilization.",
          //   },{
          //     image: Icon7,
          //     title: "Farm Updates",
          //     bodycopy:
          //       "    Keep stakeholders informed with updates on farm activities, developments, and any other relevant news or announcements.",
          //   },
      ];
  return (
    <OurServiceCardContainer services={ABNServices}  title="Features"/>
  )
}


