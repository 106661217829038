import React from 'react'
import AboutList from './AboutList'

export default function Network() {
    const data=[
  "ARN:  The Agri-Retailer Network (ARN) as a part of CBN Network is a platform provided by ADN Network to agro-dealers for effectively managing and coordinating agri-retailers within their network."  ,
// "DTN:   The Digital Transformation Network is a network provided by GBR Network to agribusinesses for managing Agri-MSMEs and facilitating their digital transformation journey within the agricultural sector.",

       
    ]
  return (
   <AboutList title="Network" contentlist={data}/>
  )
}